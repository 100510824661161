/**
 * @flow
 */

import * as React from 'react';
import ImagerySearch from 'src/components/ImagerySearch';

type Props = $ReadOnly<{}>;

export default function ImageryGalleryPage(props: Props): React.Node {
  return <ImagerySearch />;
}
